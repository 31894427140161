import React, { useState, forwardRef } from "react";
import CSVReader from 'react-csv-reader'
import MaterialTable from "material-table";

import Grid from "@material-ui/core/Grid";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Loader from "components/Loader/Loader.jsx";

import moment from "moment";
import "moment/locale/es";

import "assets/css/cargaSolicitudes.css";

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CargaMasiva(props){
    const [columnCarga] = useState([
        { title: "Id Cuenta", field: "IdCuenta", type: "string" },
        { title: "Nombre Cliente", field: "NombreCliente", type: "string" },
        { title: "Producto", field: "Producto", type: "string" },
        { title: "Últimos Dígitos", field: "UltimosDigitos", type: "string" },
        { title: "Fecha Cobro", field: "FechaCobro", type: "string" },
        { title: "Alta Renovacion", field: "AltaRenovacion", type: "string" },
        { title: "Correo", field: "Correo", type: "string" },
        { title: "RFC", field: "RFC", type: "string" },
        { title: "Telefono", field: "Telefono", type: "string" },
        { title: "Canal Venta", field: "CanalVenta", type: "string" },
        { title: "Fin Programa", field: "FinPrograma", type: "string" },
        { title: "Fecha Informacion", field: "FechaInformacion", type: "string" },
        { title: "Meses Restantes", field: "MesesRestantes", type: "string" },
        { title: "Monto Cobrado", field: "MontoCobrado", type: "string" },
        { title: "Linea Credito", field: "LineaCredito", type: "string" },
        { title: "Fecha Corte", field: "FechaCorte", type: "string" },
        { title: "Es ICR", field: "EsICR", type: "string" }
    ]);

    const headers = {
        IdCuenta: "IdCuenta",
        NombreCliente: "NombreCliente",
        Producto: "Producto",
        UltimosDigitos: "UltimosDigitos",
        FechaCobro: "FechaCobro",
        AltaRenovacion: "AltaRenovacion",
        Correo: "Correo",
        RFC: "RFC",
        Telefono: "Telefono",
        CanalVenta: "CanalVenta",
        FinPrograma: "FinPrograma",
        FechaInformacion: "FechaInformacion",
        MesesRestantes: "MesesRestantes",
        MontoCobrado: "MontoCobrado",
        LineaCredito: "LineaCredito",
        FechaCorte: "FechaCorte",
        EsICR: "EsICR"
    };

    const [dataCarga, setDataCarga] = useState([]);

    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState("");

    const [load, setLoad] = useState(false);

    const validateDate = (date) => {
        let dateArray = date.split("/");
        let validDate = "";

        if(dateArray[0].length === 4){
            validDate = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
        } else {
            let newDate = dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
            validDate = moment(newDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        }
    
        return moment(validDate, 'YYYY-MM-DD').isValid() ? validDate : "";
    };

    const convertToCSV = (objArray) => {
        const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        let str = "";
    
        for (let i = 0; i < array.length; i++) {
          str += Object.keys(array[i]).map(function(k) { return array[i][k] }).join(",") + "\r\n";
        }
    
        return str;
    };

    const exportCSVFile = (headers, items, fileName) => {
        if (headers) {
            items.unshift(headers);
        }
    
        const jsonObject = JSON.stringify(items);
        const csv = convertToCSV(jsonObject);
        const exportName = fileName + ".csv";
        var encoding = "data:text/csv;charset=utf-8,%EF%BB%BF";
    
        const link = document.createElement("a");
        link.setAttribute("href", encoding + encodeURI(csv));
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        link.click();
    };

    const SnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const handleLoaded = (data, fileInfo) => {
        let error = [];
        let headers = { no_columna: "No columna", error: "Error" };

        let valores = data.map((item, index) => {
            let fecha_cobro;
            let fin_programa;
            let fecha_informacion;
            let fecha_corte;
            let columna = index + 2;
            
            let { idcuenta, nombrecliente, producto, ultimosdigitos, fechacobro, altarenovacion, correo, rfc, telefono,
                canalventa, finprograma, fechainformacion, mesesrestantes, montocobrado, lineacredito, fechacorte, esicr } = item;

            try{        
                fecha_cobro = fechacobro === null ? null : validateDate(fechacobro);
                fin_programa = finprograma === null ? null : validateDate(finprograma);
                fecha_informacion = fechainformacion === null ? null : validateDate(fechainformacion);
                fecha_corte = fechacorte === null ? null : validateDate(fechacorte);

                if(idcuenta !== null){
                    if(idcuenta.length > 20){
                        error.push({ no_columna: columna, error: "El idcuenta debe tener menos de 20 caracteres" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "El idcuenta es obligatorio" });
                }

                if(nombrecliente !== null){
                    if(nombrecliente.length > 255){
                        error.push({ no_columna: columna, error: "El nombre del cliente debe tener menos de 255 caracteres" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "El nombre del cliente es obligatorio" });
                }
    
                if(producto !== null){
                    if(producto.length > 255){
                        error.push({ no_columna: columna, error: "El producto debe tener menos de 255 caracteres" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "El producto es obligatorio" });
                }

                if(ultimosdigitos !== null){
                    if(ultimosdigitos.length > 6){
                        error.push({ no_columna: columna, error: "Los ultimos 6 digitos deben tener menos de 6 caracteres" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "Los ultimos 6 digitos son obligatorios" });
                }
    
                if(fecha_cobro !== null) {
                    if(fecha_cobro === ""){
                        error.push({ no_columna: columna, error: "La fecha de cobro debe tener el formato aaaa/mm/dd" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "La fecha de cobro es obligatoria" });
                }
    
                if(altarenovacion !== null) {
                    if(altarenovacion.length > 100){
                        error.push({ no_columna: columna, error: "El cobro debe tener menos de 100 caracteres" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El cobro es obligatorio" });
                }

                if(correo !== null) {
                    if(correo.length > 100){
                        error.push({ no_columna: columna, error: "El correo debe tener menos de 100 caracteres" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El correo es obligatorio" });
                }

                if(rfc !== null) {
                    if(rfc.length > 13){
                        error.push({ no_columna: columna, error: "El RFC debe tener menos de 13 caracteres" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El rfc es obligatorio" });
                }

                if(telefono !== null){
                    if(telefono.length > 10){
                        error.push({ no_columna: columna, error: "El telefono debe tener menos de 10 caracteres" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "El telefono es obligatorio" });
                }

                if(canalventa !== null) {
                    if(canalventa.length > 255){
                        error.push({ no_columna: columna, error: "El canal de venta debe tener menos de 255 caracteres" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El canal de venta es obligatorio" });
                }

                if(fin_programa !== null) {
                    if(fin_programa === ""){
                        error.push({ no_columna: columna, error: "El fin programa debe tener el formato aaaa/mm/dd" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El fin programa es obligatorio" });
                }

                if(fecha_informacion !== null) {
                    if(fecha_informacion === ""){
                        error.push({ no_columna: columna, error: "La fecha informacion debe tener el formato aaaa/mm/dd" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "La fecha informacion es obligatoria" });
                }

                if(mesesrestantes !== null) {
                    if(mesesrestantes.length > 45){
                        error.push({ no_columna: columna, error: "El meses restantes debe tener menos de 45 caracteres" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El meses restantes es obligatorio" });
                }

                if(montocobrado !== null) {
                    if(parseInt(montocobrado) === NaN){
                        error.push({ no_columna: columna, error: "El monto cobrado debe ser numérico" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El monto cobrado es obligatorio" });
                }

                if(lineacredito !== null) {
                    if(parseInt(lineacredito) === NaN){
                        error.push({ no_columna: columna, error: "La linea de credito debe ser numérica" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El linea de credito es obligatorio" });
                }

                if(fecha_corte !== null) {
                    if(fecha_corte === ""){
                        error.push({ no_columna: columna, error: "La fecha corte debe tener el formato aaaa/mm/dd" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "La fecha corte es obligatoria" });
                }

                if(esicr !== null) {
                    if(esicr.length > 45){
                        error.push({ no_columna: columna, error: "El meses restantes debe tener menos de 45 caracteres" });
                    }
                }
            } catch(err) {
                error.push({ no_columna: columna, error: err.message });
            }

            return {
                IdCuenta: String(idcuenta),
                NombreCliente: String(nombrecliente),
                Producto: String(producto),
                UltimosDigitos: String(ultimosdigitos),
                FechaCobro: String(fecha_cobro),
                AltaRenovacion: String(altarenovacion),
                Correo: String(correo),
                RFC: String(rfc),
                Telefono: String(telefono),
                CanalVenta: String(canalventa),
                FinPrograma: String(fin_programa),
                FechaInformacion: String(fecha_informacion),
                MesesRestantes: String(mesesrestantes),
                MontoCobrado: parseInt(montocobrado),
                LineaCredito: parseInt(lineacredito),
                FechaCorte: String(fecha_corte),
                EsICR: String(esicr)
            };
        });

        if(error.length > 0){
            sendSnackBar("El archivo de carga contiene algunos errores.", "error");
            exportCSVFile(headers, error, "Errores");
        } else {
            setDataCarga(valores);
        }
    };

    const InsertaCapturas = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/InsertaCapturas",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const handleCarga = (e) => {
        e.preventDefault();

        if(dataCarga.length > 0){
            setLoad(true);

            Login()
            .then(result => {
                if (result !== undefined) {
                    InsertaCapturas(dataCarga, result.token)
                    .then(result => {
                        sendSnackBar("Carga exitosa.", "success");
                        setLoad(false);
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error interno, comunícate con soporte.", "error");
                    setLoad(false);
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            sendSnackBar("Debes elegir un archivo con al menos un registro.", "error");
        }
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
    };

    return (
        <div className="container">
            <Grid container>
                <GridItem xs={12} sm={12} md={6}>
                    <CSVReader
                        cssClass="csv-reader-input"
                        label="Selecciona el archivo CSV a cargar."
                        onFileLoaded={handleLoaded}
                        onError={(error) => sendSnackBar("Error al cargar el archivo", "error")}
                        parserOptions={papaparseOptions}
                        inputId="reader"
                        fileEncoding="ISO-8859-1"
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Grid container>
                        <GridItem xs={12} sm={12} md={6}>
                            <Button
                                style={{ marginTop: "40px" }}
                                onClick={e => {
                                    exportCSVFile(headers, [], 'Layout_Carga');
                                }}
                                color="info"
                            >
                                Descargar Layout
                            </Button>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <MaterialTable
                        title="Solicitudes a cargar"
                        columns={columnCarga}
                        data={dataCarga}
                        style={{marginTop: "20px"}}
                    >
                    </MaterialTable>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        style={{marginTop: "25px", float: "right"}}
                        onClick={e => {
                            handleCarga(e);
                        }}
                        color="info"
                    >
                        Cargar
                    </Button>
                </GridItem>
            </Grid> 
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
                <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </div>
    )
}