import React, { useState, useEffect, forwardRef } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// @material-ui/icons
import DateRange from "@mui/icons-material/DateRange";
import AccessTime from "@mui/icons-material/AccessTime";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Loader from "components/Loader/Loader";

import { dailySalesChart } from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import moment from "moment";
import "moment/locale/es";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(styles);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-${index}`,
  };
}

export default function Dashboard() {
  const classes = useStyles();

  const [LlamadasDiarias, setLlamadasDiarias] = useState(0);
  const [LlamadasMensuales, setLlamadasMensuales] = useState(0);

  const [RetencionesDiariasPrograma, setRetencionesDiariasPrograma] = useState(0);
  const [RetencionesMensualesPrograma, setRetencionesMensualesPrograma] = useState(0);
  const [RetencionesDiariasAcelerador, setRetencionesDiariasAcelerador] = useState(0);
  const [RetencionesMensualesAcelerador, setRetencionesMensualesAcelerador] = useState(0);
  const [RetencionesDiariasIndigo, setRetencionesDiariasIndigo] = useState(0);
  const [RetencionesMensualesIndigo, setRetencionesMensualesIndigo] = useState(0);
  const [RetencionesDiariasGlobal, setRetencionesDiariasGlobal] = useState(0);
  const [RetencionesMensualesGlobal, setRetencionesMensualesGlobal] = useState(0);

  const [PromedioRetencionesDiariasPrograma, setPromedioRetencionesDiariasPrograma] = useState(0);
  const [PromedioRetencionesMensualesPrograma, setPromedioRetencionesMensualesPrograma] = useState(0);
  const [PromedioRetencionesDiariasAcelerador, setPromedioRetencionesDiariasAcelerador] = useState(0);
  const [PromedioRetencionesMensualesAcelerador, setPromedioRetencionesMensualesAcelerador] = useState(0);
  const [PromedioRetencionesDiariasIndigo, setPromedioRetencionesDiariasIndigo] = useState(0);
  const [PromedioRetencionesMensualesIndigo, setPromedioRetencionesMensualesIndigo] = useState(0);
  const [PromedioRetencionesDiariasGlobal, setPromedioRetencionesDiariasGlobal] = useState(0);
  const [PromedioRetencionesMensualesGlobal, setPromedioRetencionesMensualesGlobal] = useState(0);

  const [LlamadasDias, setLlamadasDias] = useState([]);
  const [LlamadasNombreDias, setLlamadasNombreDias] = useState([]);
  const [LlamadasOptions, setLlamadasOptions] = useState({...dailySalesChart.options});

  const [RetencionesNombreDias, setRetencionesNombreDias] = useState([]);

  const [RetencionesDiasPrograma, setRetencionesDiasPrograma] = useState([]);
  const [RetencionesOptionsPrograma, setRetencionesOptionsPrograma] = useState({...dailySalesChart.options});
  const [RetencionesDiasAcelerador, setRetencionesDiasAcelerador] = useState([]);
  const [RetencionesOptionsAcelerador, setRetencionesOptionsAcelerador] = useState({...dailySalesChart.options});
  const [RetencionesDiasIndigo, setRetencionesDiasIndigo] = useState([]);
  const [RetencionesOptionsIndigo, setRetencionesOptionsIndigo] = useState({...dailySalesChart.options});
  const [RetencionesDiasGlobal, setRetencionesDiasGlobal] = useState([]);
  const [RetencionesOptionsGlobal, setRetencionesOptionsGlobal] = useState({...dailySalesChart.options});

  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [load, setLoad] = useState(false);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const sendSnackBar = (pmessage, pcolor) => {
    setOpenSnackbar(true);
    setColor(pcolor);
    setMessage(pmessage);
  };

  const GetIdUsuario = () => {
    return JSON.parse(localStorage.getItem('userRetencion')).IdUsuario;
  };

  const Login = async () => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
            headers: {
                "Content-Type": "application/json"
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Login",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const GetIdSucursal = () => {
    return JSON.parse(localStorage.getItem('userRetencion')).IdSucursales;
  };

  const UsuarioActivo = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const PromedioRetencion = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/PromedioRetencion",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneRetencion = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ObtieneRetencion",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const CuentaCaptura = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/CuentaCaptura",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ReporteDiario = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ReporteDiario",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  useEffect(() => {
    try{
      setLoad(true);

      let hoy = moment(Date.now());
      hoy.set({h: 0, m: 0, s: 0});

      let selectFechaInicio = moment(Date.now()).startOf('month');
      selectFechaInicio.set({h: 0, m: 0, s: 0});

      let selectFechaInicioSemana = moment(Date.now());
      selectFechaInicioSemana.set({h: 0, m: 0, s: 0});
      selectFechaInicioSemana.add(-8, 'd');

      let selectFechaFin = moment(Date.now());
      selectFechaFin.set({h: 23, m: 59, s: 59});

      let mensual = {
        FechaInicio: selectFechaInicio.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      let semana = {
        FechaInicio: selectFechaInicioSemana.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      let diario = {
        FechaInicio: hoy.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      Login()
      .then(result => {
          if (result !== undefined) {
              UsuarioActivo({ IdUsuario: GetIdUsuario() }, result.token)
              .then(result => {
                if(result.activo === false){
                  localStorage.removeItem("userRetencion");
                  return <Redirect to='/admin'/>;
                }
                setLoad(false);
              });

              CuentaCaptura(diario, result.token)
              .then(result => {
                setLlamadasDiarias(parseInt(result.promedio.Promedio));
              });

              CuentaCaptura(mensual, result.token)
              .then(result => {
                setLlamadasMensuales(parseInt(result.promedio.Promedio));
              });

              //Graficas retención
              ReporteDiario(semana, result.token)
              .then(result => {
                let capturas = result.capturas.map((element) => {
                  element.FechaCobro = element.FechaCobro === "" ? "" : moment(moment(element.FechaCobro, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FinPrograma = element.FinPrograma === "" ? "" : moment(moment(element.FinPrograma, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FechaInformacion = element.FechaInformacion === "" ? "" : moment(moment(element.FechaInformacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.id = element.IdCuenta;

                  return element;
                });

                let dia = 0;
                let nombreDias = [];
                let diasPrograma = [];
                let diasAcelerador = [];
                let diasIndigo = [];
                let diasGlobal = [];
                while (dia <= 7) {
                  let fecha = moment(Date.now());
                  fecha.set({h: 0, m: 0, s: 0});
                  fecha.add(-dia, 'd');
                  let nombreDia = fecha.format('dddd').substring(0,2).toUpperCase();

                  if(nombreDia !== 'DO') {
                    let registrosPrograma = 0;
                    let registrosAcelerador = 0;
                    let registrosIndigo = 0;
                    let registrosGlobal = 0;

                    capturas.forEach(item => {
                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')
                         && (item.IdEstatus === 1 || item.IdEstatus === 2)){
                          registrosPrograma = registrosPrograma + 1;
                      }

                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')
                        && (item.IdEstatus === 7 || item.IdEstatus === 8 || item.IdEstatus === 9)){
                         registrosAcelerador = registrosAcelerador + 1;
                      }

                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')
                        && (item.IdEstatus === 11 || item.IdEstatus === 12 || item.IdEstatus === 13)){
                         registrosIndigo = registrosIndigo + 1;
                      }

                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')
                        && (item.IdEstatus === 1 || item.IdEstatus === 2 || item.IdEstatus === 7 || 
                            item.IdEstatus === 8 || item.IdEstatus === 9 || item.IdEstatus === 11 || 
                            item.IdEstatus === 12 || item.IdEstatus === 13)){
                         registrosGlobal = registrosGlobal + 1;
                      }
                    });

                    diasPrograma.push(registrosPrograma);
                    diasAcelerador.push(registrosAcelerador);
                    diasIndigo.push(registrosIndigo);
                    diasGlobal.push(registrosGlobal);

                    nombreDias.push(nombreDia);
                  }

                  dia = dia + 1;
                }

                let capPrograma = {...RetencionesOptionsPrograma};
                capPrograma.high = Math.max(...diasPrograma) + 10;
                let capAcelerador = {...RetencionesOptionsAcelerador};
                capAcelerador.high = Math.max(...diasAcelerador) + 10;
                let capIndigo = {...RetencionesOptionsIndigo};
                capIndigo.high = Math.max(...diasIndigo) + 10;
                let capGlobal = {...RetencionesOptionsGlobal};
                capGlobal.high = Math.max(...diasGlobal) + 10;

                setRetencionesOptionsPrograma(capPrograma);
                setRetencionesDiasPrograma(diasPrograma.reverse());
                setRetencionesOptionsAcelerador(capAcelerador);
                setRetencionesDiasAcelerador(diasAcelerador.reverse());
                setRetencionesOptionsIndigo(capIndigo);
                setRetencionesDiasIndigo(diasIndigo.reverse());
                setRetencionesOptionsGlobal(capGlobal);
                setRetencionesDiasGlobal(diasGlobal.reverse());

                setRetencionesNombreDias(nombreDias.reverse());
              });

              //Grafica llamadas
              ReporteDiario(semana, result.token)
              .then(result => {
                let capturas = result.capturas.map((element) => {
                  element.FechaCobro = element.FechaCobro === "" ? "" : moment(moment(element.FechaCobro, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FinPrograma = element.FinPrograma === "" ? "" : moment(moment(element.FinPrograma, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FechaInformacion = element.FechaInformacion === "" ? "" : moment(moment(element.FechaInformacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.id = element.IdCuenta;

                  return element;
                });

                let dia = 0;
                let nombreDias = [];
                let dias = [];
                while (dia <= 7) {
                  let fecha = moment(Date.now());
                  fecha.set({h: 0, m: 0, s: 0});
                  fecha.add(-dia, 'd');
                  let nombreDia = fecha.format('dddd').substring(0,2).toUpperCase();

                  if(nombreDia !== 'DO') {
                    let registros = 0;
                    capturas.forEach(item => {
                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')){
                        registros = registros + 1;
                      }
                    });

                    dias.push(registros);
                    nombreDias.push(nombreDia);
                  }

                  dia = dia + 1;
                }

                let cap = {...LlamadasOptions};
                cap.high = Math.max(...dias) + 10;

                setLlamadasOptions(cap);

                setLlamadasDias(dias.reverse());
                setLlamadasNombreDias(nombreDias.reverse());
                
                setLoad(false);
              });

              //Retencion Programa 0
              diario["Tipo"] = 1;
              ObtieneRetencion(diario, result.token)
              .then(result => {
                setRetencionesDiariasPrograma(parseInt(result.promedio.Promedio));
              });
              mensual["Tipo"] = 1;
              ObtieneRetencion(mensual, result.token)
              .then(result => {
                setRetencionesMensualesPrograma(parseInt(result.promedio.Promedio));
              });

              //Retencion Acelerador
              diario["Tipo"] = 2;
              ObtieneRetencion(diario, result.token)
              .then(result => {
                setRetencionesDiariasAcelerador(parseInt(result.promedio.Promedio));
              });
              mensual["Tipo"] = 2;
              ObtieneRetencion(mensual, result.token)
              .then(result => {
                setRetencionesMensualesAcelerador(parseInt(result.promedio.Promedio));
              });

              //Retencion Indigo
              diario["Tipo"] = 3;
              ObtieneRetencion(diario, result.token)
              .then(result => {
                setRetencionesDiariasIndigo(parseInt(result.promedio.Promedio));
              });
              mensual["Tipo"] = 3;
              ObtieneRetencion(mensual, result.token)
              .then(result => {
                setRetencionesMensualesIndigo(parseInt(result.promedio.Promedio));
              });

              //Retencion Global
              diario["Tipo"] = 4;
              ObtieneRetencion(diario, result.token)
              .then(result => {
                setRetencionesDiariasGlobal(parseInt(result.promedio.Promedio));
              });
              mensual["Tipo"] = 4;
              ObtieneRetencion(mensual, result.token)
              .then(result => {
                setRetencionesMensualesGlobal(parseInt(result.promedio.Promedio));
              });

              //Promedio Programa 0 
              diario["Tipo"] = 1;
              PromedioRetencion(diario, result.token)
              .then(result => {
                setPromedioRetencionesDiariasPrograma(parseInt(result.promedio.Promedio));
              });
              mensual["Tipo"] = 1;
              PromedioRetencion(mensual, result.token)
              .then(result => {
                setPromedioRetencionesMensualesPrograma(parseInt(result.promedio.Promedio));
              });

              //Programa Acelerador
              diario["Tipo"] = 2;
              PromedioRetencion(diario, result.token)
              .then(result => {
                setPromedioRetencionesDiariasAcelerador(parseInt(result.promedio.Promedio));
              });
              mensual["Tipo"] = 2;
              PromedioRetencion(mensual, result.token)
              .then(result => {
                setPromedioRetencionesMensualesAcelerador(parseInt(result.promedio.Promedio));
              });

              //Programa Indigo
              diario["Tipo"] = 3;
              PromedioRetencion(diario, result.token)
              .then(result => {
                setPromedioRetencionesDiariasIndigo(parseInt(result.promedio.Promedio));
              });
              mensual["Tipo"] = 3;
              PromedioRetencion(mensual, result.token)
              .then(result => {
                setPromedioRetencionesMensualesIndigo(parseInt(result.promedio.Promedio));
              });

              //Programa Global
              diario["Tipo"] = 4;
              PromedioRetencion(diario, result.token)
              .then(result => {
                setPromedioRetencionesDiariasGlobal(parseInt(result.promedio.Promedio));
              });
              mensual["Tipo"] = 4;
              PromedioRetencion(mensual, result.token)
              .then(result => {
                setPromedioRetencionesMensualesGlobal(parseInt(result.promedio.Promedio));
              });
          } else {
              sendSnackBar("Error de conexión", "error");
              setLoad(false);
          }
      }).catch(error => sendSnackBar("Error de conexión", "error"));
    } catch(error){
        sendSnackBar("Error de conexión", "error");
        setLoad(false);
    }
  }, []);

  return (
    <div>
      <GridContainer>
        {/* Inicia llamadas */}
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" stats icon>
              <p className={classes.cardCategory}>Llamadas diarias</p>
              <h3 className={classes.cardTitle}>{LlamadasDiarias}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="primary" stats icon>
              <p className={classes.cardCategory}>Llamadas mensuales</p>
              <h3 className={classes.cardTitle}>{LlamadasMensuales}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Último mes
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="primary">
              <p>Llamadas</p>
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: LlamadasNombreDias,
                  series: [LlamadasDias]
                }}
                type="Line"
                options={LlamadasOptions}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}></h4>
              <p className={classes.cardCategory}></p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Última semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* Termina llamadas */}

        {/* Inicia Retenciones */}
        <Box sx={{ width: '100%', padding: "20px" }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="abs" centered>
              <Tab label="Global" {...a11yProps(0)} />
              <Tab label="Programa 0" {...a11yProps(1)} />
              <Tab label="Acelerador" {...a11yProps(2)} />
              <Tab label="Índigo" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <p className={classes.cardCategory}>Retención diaria</p>
                    <h3 className={classes.cardTitle}>{RetencionesDiariasGlobal}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Últimas 24 Horas
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <p className={classes.cardCategory}>Retención mensual</p>
                    <h3 className={classes.cardTitle}>{RetencionesMensualesGlobal}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Último mes
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <p className={classes.cardCategory}>Promedio retención diaria</p>
                    <h3 className={classes.cardTitle}>{PromedioRetencionesDiariasGlobal}%</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Últimas 24 Horas
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <p className={classes.cardCategory}>Promedio retención mensual</p>
                    <h3 className={classes.cardTitle}>{PromedioRetencionesMensualesGlobal}%</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Último mes
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>       
              <GridItem xs={12} sm={12} md={12}>
                <Card chart>
                  <CardHeader color="rose">
                    <p>Retenciones</p>
                    <ChartistGraph
                      className="ct-chart"
                      data={{
                        labels: RetencionesNombreDias,
                        series: [RetencionesDiasGlobal]
                      }}
                      type="Line"
                      options={RetencionesOptionsGlobal}
                      listener={dailySalesChart.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}></h4>
                    <p className={classes.cardCategory}></p>
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> Última semana
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <p className={classes.cardCategory}>Retención diaria</p>
                    <h3 className={classes.cardTitle}>{RetencionesDiariasPrograma}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Últimas 24 Horas
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <p className={classes.cardCategory}>Retención mensual</p>
                    <h3 className={classes.cardTitle}>{RetencionesMensualesPrograma}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Último mes
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <p className={classes.cardCategory}>Promedio retención diaria</p>
                    <h3 className={classes.cardTitle}>{PromedioRetencionesDiariasPrograma}%</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Últimas 24 Horas
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <p className={classes.cardCategory}>Promedio retención mensual</p>
                    <h3 className={classes.cardTitle}>{PromedioRetencionesMensualesPrograma}%</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Último mes
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>       
              <GridItem xs={12} sm={12} md={12}>
                <Card chart>
                  <CardHeader color="rose">
                    <p>Retenciones</p>
                    <ChartistGraph
                      className="ct-chart"
                      data={{
                        labels: RetencionesNombreDias,
                        series: [RetencionesDiasPrograma]
                      }}
                      type="Line"
                      options={RetencionesOptionsPrograma}
                      listener={dailySalesChart.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}></h4>
                    <p className={classes.cardCategory}></p>
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> Última semana
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <p className={classes.cardCategory}>Retención diaria</p>
                    <h3 className={classes.cardTitle}>{RetencionesDiariasAcelerador}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Últimas 24 Horas
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <p className={classes.cardCategory}>Retención mensual</p>
                    <h3 className={classes.cardTitle}>{RetencionesMensualesAcelerador}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Último mes
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <p className={classes.cardCategory}>Promedio retención diaria</p>
                    <h3 className={classes.cardTitle}>{PromedioRetencionesDiariasAcelerador}%</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Últimas 24 Horas
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <p className={classes.cardCategory}>Promedio retención mensual</p>
                    <h3 className={classes.cardTitle}>{PromedioRetencionesMensualesAcelerador}%</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Último mes
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>       
              <GridItem xs={12} sm={12} md={12}>
                <Card chart>
                  <CardHeader color="rose">
                    <p>Retenciones</p>
                    <ChartistGraph
                      className="ct-chart"
                      data={{
                        labels: RetencionesNombreDias,
                        series: [RetencionesDiasAcelerador]
                      }}
                      type="Line"
                      options={RetencionesOptionsAcelerador}
                      listener={dailySalesChart.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}></h4>
                    <p className={classes.cardCategory}></p>
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> Última semana
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <p className={classes.cardCategory}>Retención diaria</p>
                    <h3 className={classes.cardTitle}>{RetencionesDiariasIndigo}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Últimas 24 Horas
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <p className={classes.cardCategory}>Retención mensual</p>
                    <h3 className={classes.cardTitle}>{RetencionesMensualesIndigo}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Último mes
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <p className={classes.cardCategory}>Promedio retención diaria</p>
                    <h3 className={classes.cardTitle}>{PromedioRetencionesDiariasIndigo}%</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Últimas 24 Horas
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <p className={classes.cardCategory}>Promedio retención mensual</p>
                    <h3 className={classes.cardTitle}>{PromedioRetencionesMensualesIndigo}%</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <DateRange />
                      Último mes
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>       
              <GridItem xs={12} sm={12} md={12}>
                <Card chart>
                  <CardHeader color="rose">
                    <p>Retenciones</p>
                    <ChartistGraph
                      className="ct-chart"
                      data={{
                        labels: RetencionesNombreDias,
                        series: [RetencionesDiasIndigo]
                      }}
                      type="Line"
                      options={RetencionesOptionsIndigo}
                      listener={dailySalesChart.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}></h4>
                    <p className={classes.cardCategory}></p>
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> Última semana
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </CustomTabPanel>
        </Box>
        {/* Termina Retenciones */}   
      </GridContainer>
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={handleClose} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                {message}
            </Alert>
      </Snackbar>
      <Loader load={load} />
    </div>
  );
}
